import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Footer() {
  const [formData, setFormData] = React.useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fetchReq = async () => {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/subscribe",
        {
          method: "POST",
          body: JSON.stringify({
            email: formData?.email,
          }),
        }
      );
    };
    fetchReq();

    navigate("/thankyou");
  };

  return (
    <div className="flex items-start justify-around py-8 gap-8 max-w-6xl m-auto flex-wrap">
      <div className="p-4">
        <Link to="/">
          <img
            className="h-16"
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1718419894/newlogo_leechiu_sm3mgv.png"
            alt=""
          />
        </Link>
        <div className="text-xs text-left text-leechiuBlue font-semibold mt-4">
          Get updates on property market insights and real estate tips.{" "}
        </div>
        <div className="mt-4">
          <form onSubmit={handleSubmit}>
            <input
              style={{
                borderRadius: "5px 0 0 5px",
                border: "1px solid #E5E5E5",
                padding: "8px",
                width: "300px",
              }}
              type="email"
              placeholder="Enter your email address"
              className="text-xs"
              required={true}
              name="email"
              onChange={handleChange}
            />

            <button
              className="bg-leechiuBlue text-white px-4 py-2 rounded-xl hover:bg-leechiuBlue hover:text-white text-xs"
              style={{
                borderRadius: "0 5px 5px 0",
              }}
              type="submit"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
      {/* Links */}
      <div className="text-left">
        <div className="mb-4 font-serif text-leechiuOrange">Company</div>
        <div className="text-xs space-y-4 text-leechiuBlue font-semibold flex flex-col">
          <Link to="/aboutUs">About Us</Link>
          <Link to="/careers">Careers</Link>
          <Link to="/for-sale">Find Properties</Link>
          <a href="/#services">Our Services</a>
          <Link to="/faqs">FAQ</Link>
          <Link to="/privacyPolicy">Privacy Policy</Link>
        </div>
      </div>

      {/* Contact us card */}
      <div className="max-w-60 text-left">
        <div className="font-serif  text-leechiuOrange mb-4">Contact Us</div>
        <div className="text-xs space-y-4 ">
          <div>{`2/F Makati Shangri-la Arcade Ayala Avenue corner West Street, Makati City, 1200`}</div>
          <div>{`+63 2 8888 8111`}</div>
          <div>{`inquire@leechiu.com`}</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
