import React, { useEffect, useState } from "react";
import { getAllDocs } from "../Firebase.utils/Firebase.utils";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

function PropertiesForSaleCardsContainer({ searchTerm, setSearchTerm }) {
  // const [properties, setProperties] = React.useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [newFilteredList, setNewFilteredList] = useState([]);
  // const [isLoading, setIsLoading] = React.useState(true);

  const fetchProperties = async () => {
    return await getAllDocs("listings");
  };

  const { data: properties = [], isLoading } = useQuery(
    "listings",
    getAllDocs.bind(null, "listings")
  );

  // useEffect(() => {
  //   const fetchProperties = async () => {
  //     const properties = await getAllDocs("listings");
  //     setProperties(properties);
  //     setIsLoading(false);
  //   };
  //   fetchProperties();
  // }, []);

  useEffect(() => {
    if (properties.length > 0) {
      // Filter the properties based on your criteria
      const propertiesForSale = properties.filter(
        (property) => property.listing_type === "For Sale"
      );
      const propertiesForSalePipeline = propertiesForSale.filter(
        (property) => property.pipeline_status >= "30%"
      );
      const propertiesForSalePipelineDoNotUpload =
        propertiesForSalePipeline.filter(
          (property) => property.upload_website !== "No"
        );

      // Update filtered properties
      setFilteredProperties(propertiesForSalePipelineDoNotUpload);
      setNewFilteredList(propertiesForSalePipelineDoNotUpload);
    }
  }, [properties]);

  useEffect(() => {
    //concatinate property name and address
    const results = filteredProperties.filter((property) => {
      return (
        property.property_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        property.address.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setNewFilteredList(results);
  }, [searchTerm]);

  if (isLoading) {
    return (
      <div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-start justify-center gap-4 flex-wrap">
        {!isLoading &&
          newFilteredList.map((property) => (
            <div key={property.id} className="mt-8">
              <Card
                title={property.property_name}
                photo={property.primaryPhotoUrl}
                price={property.total_price}
                description={property.listing_description}
                address={property.address}
                website_title={property.website_title}
                status={property.pipeline_status}
                id={property.id}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

const Card = ({
  title,
  photo,
  price,
  description,
  address,
  website_title,
  status,
  id,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <motion.div
      className="rounded-xl min-h-[400px] w-96 overflow-hidden flex flex-col items-center justify-start
    shadow-xl bg-white  border-gray-200 border-[1px] relative hover:cursor-pointer
    "
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      onClick={() => setHovered(!hovered)}
    >
      {status === "100%" && (
        <div className="absolute top-10 bg-black bg-opacity-50  text-white rounded-lg px-4 py-2 font-serif">
          SOLD
        </div>
      )}
      {hovered && (
        <motion.div
          className="absolute inset-0 bg-neutral-950 bg-opacity-50 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div
            className="bg-white rounded-xl px-4 py-2 hover:bg-leechiuOrange hover:text-white"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
          >
            <Link
              to={`/for-sale/details?name=${website_title || title}&id=${id}`}
            >
              Learn More
            </Link>
          </motion.div>
        </motion.div>
      )}
      <div className="overflow-hidden">
        <img
          src={
            photo ||
            "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721163707/jaaddyusz9znhnxeo2ee.jpg"
          }
          alt="title"
          className="h-60 w-96 object-cover"
        />
      </div>
      <div className="px-2 py-2 w-full">
        <div className="font-sans font-bold uppercase text-leechiuBlue text-left">
          {website_title || title}
          <div className="h-[2px] bg-opacity-55 mb-2 w-full bg-leechiuOrange"></div>
        </div>
        <div className="font-bold uppercase text-leechiuOrange text-xs text-left">
          Asking Price: Php {parseFloat(price).toLocaleString("en")}
        </div>
        <div className="text-leechiuBlue text-xs text-left mt-2">
          <strong>Address</strong>: {address}
        </div>

        <div className="text-xs text-left text-leechiuBlue">
          <strong>Description</strong>: {description}
        </div>
        {/* <div className="uppercase font-bold text-leechiuOrange text-xs text-left">Learn More</div> */}
      </div>
    </motion.div>
  );
};

export default PropertiesForSaleCardsContainer;
