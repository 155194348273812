import React from "react";
import { Link } from "react-router-dom";

function UnderConstruction() {
  return (
    <div className="bg-leechiuBlue text-white">
      {/* create a page 404 under construction */}
      <div className="flex flex-col items-center justify-center p-8 h-[400px]">
        <h1 className="font-serif text-4xl font-bold">
          This Page is Being Updated
        </h1>
        <p className="text-lg text-leechiuOrange">
          We are working on something awesome
        </p>
        <div className="mt-4 flex items-center gap-4">
          <span className="font-semibold">Looking for something?</span>{" "}
          <Link
            className="bg-leechiuOrange px-4 py-2  rounded-lg"
            to={"/contactUs"}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UnderConstruction;
