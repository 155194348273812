import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import ClientsLandlordRep from "./ClientsLandlordRep";
import PropertiesCardsSection from "./PropertiesCardsSection";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function InvestmentSales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const investmentSalesCards = [
    {
      title: "Capital Markets",
      description:
        "We assist in identifying potential investors for your property, utilizing our extensive network and market expertise to attract buyers or partners that align with your objectives.",
    },
    {
      title: "Residential Property",
      description:
        "Our team negotiates lease terms with prospective tenants to secure favorable agreements on your behalf, maximizing rental income and minimizing vacancies.",
    },
    {
      title: "Mixed-Use Property",
      description:
        " We manage lease renewals and restructuring agreements to ensure continued tenant satisfaction and maximize property value.",
    },
    {
      title: "Market Analysis",
      description:
        "Utilizing comprehensive market analysis, we provide insights into current market trends and competitor analysis to inform strategic decision-making.",
    },
    {
      title: "Transaction Management",
      description:
        "From document preparation to finalizing contracts, we oversee the entire leasing process to ensure efficiency and compliance with legal requirements.",
    },
  ];

  return (
    <div className="bg-leechiuBlue">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Investment Sales | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Our investment sales brokers facilitate the sale or acquisition of various properties including office buildings, land for development, industrial properties, apartment blocks, hotels, and islands. They use conventional selling and property bidding methods."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url('https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721027144/leechiuweb3/Investment%20Sales/cirnffkcolrbccxcccv7.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="h-full w-full bg-leechiuBlue bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl">Investment Sales</div>
        </div>
      </div>
      <div className=" flex items-center justify-center flex-wrap">
        <div className="w-[600px] bg-white text-left px-16 py-8">
          <div className="font-semibold">
            <div className="font-serif text-4xl text-leechiuBlue">
              Guiding You with
            </div>
            <div className="font-serif text-4xl text-leechiuOrange">
              Expertise
            </div>
          </div>
          <div className="text-leechiuBlue mt-8">
            Navigating the complexities of the investment landscape requires
            strategic guidance. With a deep understanding of the market, we
            provide unparalleled service to help investors achieve their
            financial goals.
          </div>
        </div>
        <div className="max-w-[800px]">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721027654/leechiuweb3/Investment%20Sales/asian-businessman-leads-presentation-with-colleagu-2023-11-27-05-20-47-utc_1_v762nf.jpg"
            alt=""
            style={{
              maximumWidth: "800px",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
      <div className="text-white mt-8">
        <div className="font-serif text-4xl font-semibold">
          {"Our "}
          <span className="text-leechiuOrange">Services</span>
        </div>
        <div className="max-w-6xl m-auto mt-4">
          Elevate your real estate investment game with our comprehensive
          Investment Sales Services. Our team of skilled brokers excels in
          facilitating the sale or lease of commercial properties, structuring
          profitable joint ventures, and offering expert advice on various types
          of real estate investment opportunities. Whether you're eyeing raw
          land, commercial lots, or seeking to explore joint venture
          possibilities, our tailored approach and industry expertise will guide
          you towards lucrative and strategic investment decisions. Trust us to
          maximize your investment potential and achieve success in the dynamic
          world of real estate.
        </div>
      </div>
      <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
        {investmentSalesCards.map((card, index) => {
          return (
            <div
              className="h-32
              max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
              key={index}
            >
              <CheckCircleOutlineIcon />
              <div className="text-leechiuBlue">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative ">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721028471/leechiuweb3/Investment%20Sales/handsome-businessmen-working-2023-11-27-04-51-52-utc_1_aal2tx.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {` We offer comprehensive services tailored to meet the unique needs of each investor. From property valuation and market analysis to deal structuring, negotiations, and closing, our dedicated team of professionals is committed to facilitating successful investment transactions. `}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-8 overflow-hidden p-8">
        {/* <div className="font-serif text-3xl text-white text-center mb-8 font-semibold">
          Best Property to Invest Right Now
        </div> */}
        {/* <PropertiesCardsSection /> */}
      </div>
      <ClientTestimonials />
    </div>
  );
}

export default InvestmentSales;
