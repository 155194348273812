import React from "react";

import Hero from "./Hero";
import ServicesSection from "./ServicesSection";
import FeaturedProperties from "./FeaturedProperties";
import OurInsights from "./OurInsights";
import AboutUs from "./AboutUs";


import { useEffect } from "react";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero />
      <ServicesSection />
      <FeaturedProperties />
      <div className="h-20 w-full" />
      {/* <OurInsights /> */}
      <AboutUs />
    </div>
  );
}

export default Home;
