import React from "react";

function Partners() {
  const partners = [
    {
      name: "uli",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677426/leechiu/partners%20and%20affiliates/ulilogo_iudrgq.png",
    },
    {
      name: "ccap",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677425/leechiu/partners%20and%20affiliates/partner-4_vxpvki.png",
    },
    {
      name: "nordcham",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677425/leechiu/partners%20and%20affiliates/Nordcham_Logo_qxycge.png",
    },
    {
      name: "ibpap",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677424/leechiu/partners%20and%20affiliates/IBPAP_logo_o2d6mu.png",
    },
    {
      name: "amcham",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677424/leechiu/partners%20and%20affiliates/Amcham_xjs7mt.png",
    },
    {
      name: "eccp",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677424/leechiu/partners%20and%20affiliates/ECCP-Logo-Full-Colour_q7j0d2.png",
    },
    {
      name: "himap",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/HIMAP-logo_jymzvw.png",
    },
    {
      name: "map",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/MAP-OGImage-1200x630-1_lytfu0.png",
    },
    {
      name: "psia",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/11371_PSIA-Logo_wrrj6j.png",
    },
    {
      name: "bccp",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/3a8898d8-022a-4517-b53a-2d1c710251f4_xorzgu.png",
    },
    {
      name: "wallace",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677427/leechiu/partners%20and%20affiliates/Wallace_Business_Forum_WBF_LOGO_FINAL_ziy0ii.png",
    },
  ];

  return (
    <div>
      <div>
        <div className="flex flex-wrap justify-center max-w-5xl m-auto gap-4">
          {partners.map((partner) => (
            <div className="m-4" key={partner.name}>
              <img src={partner.logo} alt={partner.name} className="h-12 " />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Partners;
