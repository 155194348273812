import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import ClientsLandlordRep from "./ClientsLandlordRep";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Research() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const landloredRepCards = [
    {
      title: "Comprehensive Property Reports",
      description:
        "Access detailed and comprehensive property reports that go beyond surface-level information, providing a deep understanding of each asset.",
    },
    {
      title: "Tailored to Your Business",
      description:
        "Our services are customized to suit your unique business requirements, ensuring the information you receive is relevant and impactful.",
    },
    {
      title: "Strategic Decision-Making",
      description:
        " We manage lease renewals and restructuring agreements to ensure continued tenant satisfaction and maximize property value.",
    },
    {
      title: "Market Trends Analysis",
      description:
        "Stay ahead of the curve with our in-depth analysis of market trends, enabling you to anticipate shifts and capitalize on emerging opportunities.",
    },
    {
      title: "Expert Consultancy",
      description:
        "Benefit from the expertise of our seasoned consultants who are dedicated to providing you with valuable insights and guidance.",
    },
  ];

  return (
    <div className="bg-leechiuBlue">
    <Helmet>
        <meta charSet="utf-8" />
        <title>Research | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Our consulting team offers a full suite of services that includes market research, highest and best use analysis, project conceptualization, and financial modelling to determine project feasibility. Recently, they have started to use data science to analyze large quantities of data."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721034480/leechiuweb3/Research%20and%20Property%20Valuation/finance-manager-meeting-discussing-company-growth-2023-11-27-04-57-13-utc_1_ycheiu.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl font-semibold">
            Research and Consultancy
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-center flex-wrap">
        <div className="">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720928802/leechiuweb3/Landlord%20Representation/young-happy-smiling-couple-read-agreement-with-rea-2023-12-06-16-07-38-utc_p7mrns.jpg"
            alt=""
            style={{
              maximumWidth: "800px",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="w-[600px] bg-white text-left px-16 py-8">
          <div className="font-semibold">
            <div className="font-serif text-4xl text-leechiuBlue">
              Our Insights
            </div>
            <div className="font-serif text-4xl text-leechiuOrange">
              Your Success
            </div>
          </div>
          <div className="text-leechiuBlue mt-8">
            Navigating the complexities of the investment landscape requires
            strategic guidance. With a deep understanding of the market, we
            provide unparalleled service to help investors achieve their
            financial goals.
          </div>
        </div>
      </div>

      <div>
        <div className="text-white mt-8">
          <div className="font-serif text-4xl font-semibold">
            {"Our "}
            <span className="text-leechiuOrange">Services</span>
          </div>
          <div className="max-w-6xl m-auto mt-4">
            Our Research and Consultancy Services offer a strategic edge to your
            real estate ventures. Specializing in providing expert guidance on
            real estate investment products, conducting in-depth highest and
            best use studies, and delivering comprehensive feasibility studies,
            our team is dedicated to helping you make informed and profitable
            decisions in the dynamic real estate market. Whether you are looking
            to optimize property usage, evaluate investment opportunities, or
            enhance project feasibility, our tailored consultancy services are
            designed to empower you with actionable insights and strategic
            recommendations. Trust us to drive the success of your real estate
            projects through meticulous research and expert consultation.
          </div>
        </div>
        <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
          {landloredRepCards.map((card, index) => {
            return (
              <div
                className="h-32
              max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
                key={index}
              >
                <CheckCircleOutlineIcon />
                <div className="text-leechiuBlue">
                  <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                    {card.title} : {""}
                    <span className="text-xs sm:text-sm font-normal">
                      {card.description}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative ">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {` We provide comprehensive research and consultancy services tailored to meet your unique needs. Leveraging our expertise, extensive network, and analytical capabilities, we offer value-added solutions, such as market analysis, trend forecasting, investment strategies, and risk assessment. Our experienced team is dedicated to providing strategic guidance and actionable insights to help you achieve your real estate objectives.`}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                Contact Us
              </div>
            </div>
          </div>
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720934084/leechiu/Landlord%20Representation/happy-middle-aged-business-man-hiring-recruit-at-j-2024-02-09-16-00-55-utc_1_cpyyxa.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
        </div>
      </div>
      <ClientTestimonials />
      <ClientsLandlordRep />
    </div>
  );
}

export default Research;
