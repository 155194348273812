import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function TenantRep() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardsDetails = [
    {
      title: "Space Selection",
      description:
        "We help you identify the right space for your business, taking into account factors such as location, size, and budget.",
    },
    {
      title: "Lease Negotiation",
      description:
        "We negotiate with landlords to secure favorable lease terms, including rent, lease duration, and renewal options.",
    },
    {
      title: "Lease Renewal and Restructuring",
      description:
        "We assist in negotiating lease renewals and restructuring agreements to ensure that you get the best deal possible.",
    },
    {
      title: "Market Analysis",
      description:
        "We provide detailed market analysis to help you understand current market trends and make informed decisions. ",
    },
    {
      title: "Transaction Management",
      description:
        "We manage the entire leasing process, including document preparation, to ensure a smooth and efficient transaction.",
    },
  ];

  return (
    <motion.div className="bg-leechiuBlue h-fit ">
      {/* first section */}

      <Helmet>
        <meta charSet="utf-8" />
        <title>Tenant Representation | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            " Our tenant representation specialists facilitate lease acquisitions, renewals and restructuring for their clients. These services are provided together with various tools that help our clients determine the best real estate strategy."
          }
        />
      </Helmet>

      <div
        className="h-[500px]"
        style={{
          backgroundImage:
            'url("https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713799712/leechiu/Tenant%20Representation/hzf7jenrhsirdblyod07.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue bg-opacity-60 h-full flex items-center justify-center">
          <div className="font-serif text-4xl text-white font-semibold">
            Tenant Representation
          </div>
        </div>
      </div>

      {/* second section */}
      <div className="bg-leechiuBlue text-white p-8">
        <div className="max-w-6xl m-auto space-y-4">
          <div className="font-serif text-4xl font-semibold">
            Pioneering Commercial Leasing{" "}
            <span className="text-leechiuOrange"></span>
          </div>
          <div className="text-xl">{`
        We specialize in tenant representation, ensuring that your business gets the best deal. With our experienced team of brokers and comprehensive services, we are your exclusive partner in navigating the complex real estate market in the Philippines.
        `}</div>
        </div>

        <div className="mt-8 max-w-6xl m-auto space-y-4">
          <div className="text-3xl font-serif font-semibold">
            Our <span className="text-leechiuOrange">Solutions</span>
          </div>
          <div className="text-xl">
            {`From lease negotiations to site selection, our dedicated team is committed to providing personalized solutions that meet your unique needs. `}
          </div>
        </div>
      </div>

      {/* third section */}
      <div className="bg-leechiuBlue flex items-center gap-4 p-8 flex-wrap justify-center">
        {cardsDetails.map((card, index) => {
          return (
            <div
              className="h-32
             max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
            >
              <CheckCircleOutlineIcon className="text-leechiuBlue" />
              <div className="">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* fourth section */}
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative ">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713799707/leechiu/Tenant%20Representation/sxbro2cn2836nzf1krqs.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />

          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-2xl p-8 text-left">
              {`We specialize in advocating for tenants' interests to secure the best possible outcomes. We aim to empower tenants to make informed decisions and achieve their real estate goals. `}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default TenantRep;
