import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import ClientsLandlordRep from "./ClientsLandlordRep";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Residential() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backImg =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721224381/leechiuweb3/Residential/real-estate-agent-handing-over-the-keys-2023-11-27-04-56-51-utc_1_rujs2m.jpg";
  return (
    <div className="bg-leechiuBlue">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Residential | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Find your dream home in the Philippines with Leechiu Property Consultants. Explore our curated selection of luxury condos, family homes, and exclusive residential communities."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl font-semibold">
            Residential Sales and Leasing
          </div>
        </div>
      </div>

      <FirstDescription />
      {/* <Accreditation /> */}
      <div className="h-12 w-full" />
      <DescriptionBand />
      <SolutionsCard />
      <ClientTestimonials />
      <ClientsLandlordRep />
    </div>
  );
}

const FirstDescription = () => {
  return (
    <div className=" flex items-center justify-center flex-wrap bg-leechiuBlue">
      <div className="max-w-[800px]">
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721225359/leechiuweb3/Residential/asian-big-family-moving-packing-boxes-and-cloth-ba-2023-11-27-05-34-24-utc_1_m5yrcz.jpg"
          alt=""
          style={{
            maximumWidth: "800px",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="w-[600px] bg-white text-left px-16 py-8">
        <div className="font-semibold">
          <div className="font-serif text-4xl text-leechiuBlue">
            Your Dream Home
          </div>
          <div className="font-serif text-4xl text-leechiuOrange">
            Our Expertise
          </div>
        </div>
        <div className="text-leechiuBlue mt-8">
          {`Our residential and project leasing team, with an average of 20 years of industry experience, provides a comprehensive range of services. From selling and leasing to tax assistance and bank loan support, we offer expert guidance in residential real estate transactions.`}
        </div>
      </div>
    </div>
  );
};

const Accreditation = () => {
  const accreditedAgencies = [
    {
      name: "Bangko Sentral ng Pilipinas",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Bangko_Sentral_ng_Pilipinas_2020_logo.png/800px-Bangko_Sentral_ng_Pilipinas_2020_logo.png",
    },
    {
      name: "Securities and Exchange Commission",
      logo: "https://www.sec.gov.ph/wp-content/uploads/2019/12/LogoNew-300x185.png",
    },
    {
      name: "Philippine Stock Exchange",
      logo: "https://cdn-asset.aseanexchanges.org/wp-content/uploads/2023/06/PSE-Logo.png",
    },
  ];

  return (
    <div className="text-leechiuBlue mt-12 bg-white p-8">
      <div className="font-serif text-4xl font-semibold text-center ">
        Accreditations
      </div>
      <div className="flex items-end justify-center gap-8 mt-8">
        {accreditedAgencies.map((agency) => (
          <div className="flex items-center justify-center flex-col">
            <img src={agency.logo} alt={agency.name} className="h-20" />
            <div>{agency.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DescriptionBand = () => {
  return (
    <>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721225802/leechiuweb3/Residential/real-estate-agent-giving-keys-to-happy-arab-buyers-2023-11-27-05-00-08-utc_1_n1eybf.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {` Our residential and project leasing team, with 20 years of industry experience on average, provides a wide range of services. From selling and leasing to tax assistance and bank loan support, we offer expertise in residential real estate transactions.`}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                <Link to="/contactUs">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SolutionsCard = () => {
  const cards = [
    {
      title: "Personalized Property Search & Selection",
      description:
        "Our expert team conducts thorough searches tailored to your specific needs and budget. Comprehensive inspections ensure you find the perfect residential property that meets your criteria.",
    },
    {
      title: "Targeted Marketing & Property Exposure",
      description:
        "We leverage our extensive online platforms and innovative marketing strategies to showcase your properties to the right network of qualified buyers and tenants.",
    },
    {
      title: "Data-Driven Pricing Strategy",
      description:
        "Backed by our research department's meticulous data analysis (macro and micro), we provide strategic guidance on competitively pricing your property to optimize value while considering current market trends and dynamics.",
    },
    {
      title: "Streamlined Documentation & Legal Support",
      description:
        "We handle all documentation meticulously and efficiently, ensuring compliance with legal requirements and safeguarding your interests throughout the entire sales or leasing process.",
    },
    {
      title: "Real Estate Tax Guidance",
      description:
        "Benefit from expert advice on taxation to ensure full compliance with real estate tax laws and regulations",
    },
  ];

  return (
    <div>
      <div className="text-white mt-8">
        <div className="font-serif text-4xl font-semibold">
          {"Our "}
          <span className="text-leechiuOrange">Solutions</span>
        </div>
        <div className="max-w-6xl m-auto mt-4">
          {`Our Residential Services are designed to meet the needs of both investors seeking to lease residential properties and tenants searching for their ideal home, whether it's a house or a condo. With a focus on providing personalized and attentive service, our team ensures that investors find lucrative leasing opportunities while tenants discover the perfect place to call home. From assisting with property showings and lease negotiations to guiding tenants through the rental process, we are committed to facilitating smooth transactions and fostering successful partnerships between landlords and tenants. Whether you are looking to invest in residential properties or find a new place to live, our tailored services are here to address your unique needs with professionalism and expertise.`}
        </div>
      </div>
      <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
        {cards.map((card, index) => {
          return (
            <div
              className="h-32
          max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
              key={index}
            >
              <CheckCircleOutlineIcon />
              <div className="text-leechiuBlue">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Residential;
