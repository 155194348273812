import React from "react";

function LaurelBand() {
  return (
    <div className="w-full h-[120px] bg-slate-900 bg-opacity-40 bg flex items-center justify-around py-4 backdrop-blur-sm divide-x-2 divide-blue-400">
      <div className="flex-grow text-center">
        <div className="font-serif font-bold text-2xl">Php 32 Billion </div>
        <div className="text-xs">Value of Closed Transactions</div>
      </div>
      <div className="flex-grow text-center">
        <div className="font-serif font-bold text-2xl">12,272 </div>
        <div className="poppins-regular text-xs">
          Closed Sales in Cumulative Years
        </div>
      </div>
      <div className="flex-grow text-center">
        <div className="font-serif font-bold text-2xl">Php 12 Billion </div>
        <div className="poppins-regular text-xs">
          Total Value of Active Listings
        </div>
      </div>
      <div className="flex-grow text-center">
        <div className="font-serif font-bold text-2xl">2,143</div>
        <div className="poppins-regular text-xs">
          Closed Financing in Most Recent Year
        </div>
      </div>
    </div>
  );
}

export default LaurelBand;