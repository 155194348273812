import React from "react";
import PropertiesForSaleCardsContainer from "./PropertiesForSaleCardsContainer";
import { Helmet } from "react-helmet";

function PropertiesForSale() {
  const [searchTerm, setSearchTerm] = React.useState("");

  const bgImage =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721353594/for%20sale/aerial-view-of-makati-2023-11-27-05-24-21-utc_1_fxuqrd.jpg";

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Properties For Sale | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Looking to buy property in the Philippines? Browse Leechiu's diverse portfolio of properties for sale, including residential, commercial, and industrial listings nationwide."
          }
        />
      </Helmet>
      <div
        className="w-full h-[500px]"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="h-full w-full bg-leechiuBlue  bg-opacity-60 flex flex-col items-center justify-center gap-2">
          <div className="pl-12 sm:pl-32 font-serif text-4xl text-white w-full text-left font-semibold">
            Properties for Sale
          </div>
          <div className="pl-12 sm:pl-32 text-white text-left text-lg font-semibold">
            Find properties for sale across all major property types, including
            office, industrial, retail, and data centers. Let us guide you to
            your next investment or leasing opportunity.
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between max-w-6xl m-auto mt-8 flex-wrap max-sm:ml-4 gap-4">
        <div className="flex items-center justify-center">
          <button
            className="w-fit px-4 py-2 bg-leechiuBlue text-white"
            style={{
              borderRadius: "10px 0 0 10px",
              border: "1px solid #000",
            }}
          >
            View All
          </button>
          <button
            className="w-fit px-4 py-2 "
            style={{
              borderRadius: "0 10px 10px 0",
              border: "1px solid #000",
            }}
          >
            Most Recent
          </button>
        </div>
        <div className="flex items-center justify-center gap-4 ">
          <input
            type="text"
            placeholder="Search for properties"
            className="min-w-60 px-4 py-2"
            style={{
              borderRadius: "10px",
              border: "1px solid #000",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="px-4 py-2 w-fit "
            style={{
              borderRadius: "10px",
              border: "1px solid #000",
            }}
          >
            Filters
          </button>
        </div>
      </div>
      <div>
        <PropertiesForSaleCardsContainer
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
}

export default PropertiesForSale;
