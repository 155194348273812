import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ref, deleteObject, getStorage } from "firebase/storage";

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  writeBatch,
  deleteDoc,
  query,
  where,
  addDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDidQf3DPxg02L7lX6-D2ICwuTfynOmKT8",
  authDomain: "database-project-32188.firebaseapp.com",
  projectId: "database-project-32188",
  storageBucket: "database-project-32188.appspot.com",
  messagingSenderId: "414731493554",
  appId: "1:414731493554:web:6e96e14c5af561111da9ab",
};

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const auth = getAuth();

export default app;

export const storage = getStorage(app);

export const db = getFirestore();

export const signInWithEmail = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      alert("User not found, please sign up instead");
    }
  }
};

export const findUserDocbyId = async (id) => {
  const userRef = doc(db, "users", id);
  const snapShot = await getDoc(userRef);
  if (snapShot.exists()) {
    return snapShot.data();
  } else {
    return false;
  }
};

export const createNewDocument = async (collectionName, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = await addDoc(collectionRef, data);
    return docRef;
  } catch (error) {
    alert(error.message);
    return error;
  }
};

export const addNewDoc = async (collectionName, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = await addDoc(collectionRef, data);
    return docRef;
  } catch (error) {
    alert(error.message);
    return error;
  }
};

export const getAllDocs = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getAllPhotosById = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("listingId", "==", id));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getAllBuildingPhotosById = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("buildingId", "==", id));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getAllDocsSnap = (collectionName, callback) => {
  try {
    const collectionRef = collection(db, collectionName);

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const getAllBuildingSpaces = (collectionName, id, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("buildingId", "==", id));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const getAllBuildingPhotosSnap = (collectionName, id, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("buildingId", "==", id));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const getAllTasks = (collectionName, id, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("listing_id", "==", id));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const getAllUserTasks = (collectionName, email, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(
      collectionRef,
      where("assignee_emails", "array-contains", email),
      where("status", "==", "Open")
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const setDocData = async (collectionName, id, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    return error;
  }
};

export const getAllDocsByEmail = async (collectionName, email, field) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where(field, "==", email));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getAllAccounts = async (collectionName, email, field) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where(field, "array-contains", email));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getDocById = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (error) {
    return error;
  }
};

export const getDocData = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (error) {
    return error;
  }
};

export const getUserByEmail = async (collectionName, email) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const docs = [];

    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs[0];
  } catch (error) {
    return error;
  }
};

export const getUserByEmailSnap = (collectionName, email, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("email", "==", email));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      callback(docs[0]);
    });
    return unsubscribe;
  } catch (error) {
    return error;
  }
};

export const getDocDataSnap = async (collectionName, id, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        callback(docSnap.data());
      } else {
        callback(false);
      }
    });

    // Return the unsubscribe function so that you can stop listening when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error; // Re-throw the error for handling in the calling code
  }
};

export const updateDocById = async (collectionName, id, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    return error;
  }
};

export const deleteDocById = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await deleteDoc(docRef);
    return true;
  } catch (error) {
    return error;
  }
};

export const countDocsByStatusAndEmail = async (
  collectionName,
  email,
  status,
  field
) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(
      collectionRef,
      where("status", "==", status),
      where(field, "==", email)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    return error;
  }
};

export const getAllDocsByEmailAndStatus = async (
  collectionName,
  email,
  status,
  field
) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(
      collectionRef,
      where("status", "==", status),
      where(field, "==", email)
    );
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const signupWithEmail = async (email, password) => {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return response;
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      alert("Email already in use, please sign in instead");
    }
  }
};

export const updateUserDisplayName = async (displayName) => {
  try {
    const user = auth.currentUser;
    await user.updateProfile({
      displayName: displayName,
    });
    return true;
  } catch (error) {
    return error;
  }
};

export const checkUser = async (userAuth) => {
  if (userAuth) {
    //check if user is existing
    const userRef = doc(db, "users", userAuth.uid);
    const userSnapShot = await getDoc(userRef);

    if (!userSnapShot.exists()) {
      return false;
    } else {
      return true;
    }
  }
};

export const createUserDoc = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const colref = collection(db, "users");
  const userRef = doc(colref, userAuth.uid);
  const snapShot = await getDoc(userRef);

  if (!snapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      alert(error.message);
    }
  }
  return userRef;
};

export const queryAllDocs = async (collectionName, field, order) => {
  try {
    const q = query(collection(db, collectionName), orderBy(field, order));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const queryAllDocsByField = async (
  collectionName,
  field,
  whereClause,
  value
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(field, whereClause, value)
    );
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const queryOnSnapshot = async (
  collectionName,
  field,
  whereClause,
  value,
  callback
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(field, whereClause, value),
      orderBy("created_at", "desc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      callback(docs);
    });
    return unsubscribe;
  } catch (error) {
    return error;
  }
};

export const queryAllListings = async (
  collectionName,
  field,
  whereClause,
  value
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(field, whereClause, value),
      orderBy("created_at", "desc")
    );
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const resetpassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return error;
  }
};

export const deleteObjectByUrl = async (url) => {
  try {
    const storageRef = ref(storage, url);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    return error;
  }
};

export const uploadBatchDocs = async (collectionName, data) => {
  try {
    const batch = writeBatch(db);
    data.forEach((item) => {
      const docRef = doc(collection(db, collectionName));
      batch.set(docRef, item);
    });
    await batch.commit();
    alert("Batch upload successful");
    return true;
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getAllDocsSnapByEmail = (collectionName, email, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(
      collectionRef,
      where("team_members", "array-contains", email)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      callback(docs);
    });
    return unsubscribe;
  } catch (error) {
    return error;
  }
};


export const getAllExlusiveBuildings = async (collectionName, field, value) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where(field, "==", value));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
}
