import React from "react";
import { useParams } from "react-router-dom";
import { getDocData } from "../Firebase.utils/Firebase.utils";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function BuildingDetails() {
  const [buildingDetails, setBuildingDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();

  useEffect(() => {
    const fetchBuildingDetails = async () => {
      const building = await getDocData("masterlist", id);
      setBuildingDetails(building);
      setIsLoading(false);
      setSearchParams({ name: building.buildingName });
    };
    fetchBuildingDetails();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const tableFields = [
    {
      label: "Building Name",
      value: "buildingName",
      inputType: "text",
    },
    {
      label: "Address",
      value: "address",
      inputType: "text",
    },
    {
      label: "Business District",
      value: "businessDistrict",
      inputType: "text",
    },
    {
      label: "City",
      value: "city",
      inputType: "text",
    },

    {
      label: "Developer",
      value: "developer",
      inputType: "text",
    },
    {
      label: "Completion Date",
      value: "yearConstructed",
      inputType: "text",
    },
    {
      label: "Certifications",
      value: "certifications",
      inputType: "text",
    },
    {
      label: "Total Floors",
      value: "totalFloors",
      inputType: "number",
    },
    {
      label: "Density",
      value: "density",
      inputType: "text",
    },

    {
      label: "Floor Efficiency",
      value: "floorEfficiency",
      inputType: "text",
    },

    {
      label: "AC System",
      value: "acSystem",
      inputType: "text",
    },

    {
      label: "Building Grade",
      value: "buildingGrade",
      inputType: "text",
    },
    {
      label: "Total Gross Leasable Area",
      value: "grossLeasableArea",
      inputType: "text",
    },
    {
      label: "Gross Leasable Area per floor",
      value: "floorPlate",
      inputType: "text",
    },
    {
      label: "Net Usable Area per floor",
      value: "netUseableArea",
      inputType: "text",
    },
    {
      label: "Backup Power",
      value: "backupPower",
      inputType: "text",
    },
    {
      label: "Elevators",
      value: "elevators",
      inputType: "text",
    },
    {
      label: "Telco Providers",
      value: "telcoProviders",
      inputType: "text",
    },
    {
      label: "Ceiling Height",
      value: "ceilingHeight",
      inputType: "text",
    },
    {
      label: "Parking Allocation",
      value: "parkingAllocation",
      inputType: "text",
    },
    {
      label: "24/7 Operations",
      value: "twentyFourSevenOperations",
      inputType: "text",
      placeholder: "Yes/No",
    },

    // {
    //   label: "Retail Tenant in Office Floors",
    //   value: "retailTenantInOfficeFloors",
    //   inputType: "text",
    //   placeholder: "Yes/No",
    // },
    // {
    //   label: "BPO",
    //   value: "bpo",
    //   inputType: "text",
    //   placeholder: "Yes/No",
    // },
    // {
    //   label: "Online Gaming",
    //   value: "onlineGaming",
    //   inputType: "text",
    //   placeholder: "Yes/No",
    // },
  ];

  return (
    <div className="px-4 py-2 max-w-[1440px] m-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {buildingDetails.buildingName} | Office Space | Leechiu Property
          Consultants
        </title>
        <meta
          name="description"
          content={
            "Searching for the perfect office space for your business? Leechiu Property Consultants offers premium commercial properties for lease, including prime office locations in major cities across the Philippines."
          }
        />
      </Helmet>

      <div className="uppercase text-leechiuBlue font-bold text-lg text-left">
        Office Buildings for Lease
      </div>
      <div className="h-[10px] w-[400px] bg-leechiuOrange mb-8"></div>
      <div className="font-sans font-bold text-leechiuBlue uppercase text-left mb-4 flex items-center gap-6">
        <div className="text-3xl uppercase">{buildingDetails.buildingName}</div>
      </div>

      <div className="text-left mb-4 text-gray-600 ml-12 max-w-[90%]">
        {buildingDetails.description}
      </div>

      <div className="flex items-start justify-center gap-4 flex-wrap">
        <div
          className="px-4 py-2"
          style={{
            borderRadius: "10px",
            border: "1px solid",
            borderColor: "#E5E5E5",
          }}
        >
          <div className="font-sans text-xl uppercase text-leechiuBlue font-bold flex flex-col items-center justify-center">
            Building Photos
          </div>
          <div className="w-[390px] sm:w-[700px] aspect-square grow-0 mt-4">
            <img
              className="h-full w-full object-cover px-4 py-2"
              src={buildingDetails.primaryPhotoUrl}
              alt=""
            />
          </div>
        </div>
        <div
          className=" w-[390px] sm:w-[600px] grow flex flex-col items-center"
          style={{
            borderRadius: "10px",
            border: "1px solid",
            borderColor: "#E5E5E5",
          }}
        >
          <div className="font-sans text-xl text-leechiuBlue font-bold uppercase sm:w-[600px] w-[500px] px4 py-2">
            Technical Information
          </div>
          <div className="sm:w-[600px] w-[390px] px-4 py-2">
            <BuildingDetailsTable
              formData={buildingDetails}
              tableFields={tableFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildingDetails;

const BuildingDetailsTable = ({ formData, tableFields }) => {
  return (
    <div className="flex items-center flex-wrap max-w-5xl text-sm">
      <table className="w-full bg-white border border-gray-300  text-neutral-800 mt-4">
        <tbody>
          {tableFields.map((field, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              style={{
                textAlign: "left",
              }}
            >
              <td className="border border-gray-300 px-2 py-1">
                {field.label}
              </td>
              {field.value === "totalAvailableSpace" ? (
                <td className="border border-gray-300 px-2 py-1">
                  {parseFloat(formData[field.value]).toLocaleString()} sqms
                </td>
              ) : (
                <td className="border border-gray-300 px-2 py-1">
                  {formData[field.value]}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
