import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import ClientsLandlordRep from "./ClientsLandlordRep";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Hotels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bgImg =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721228489/leechiuweb3/Hospitality/el-nido-palawan-philippines-exotic-beach-with-p-2023-11-27-05-02-43-utc_1_h7cpuj.jpg";
  return (
    <div className="bg-leechiuBlue">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hotel, Tourism, and Leisure | LPC</title>
        <meta
          name="description"
          content={
            "Maximize the potential of your hospitality investment with LPC's specialized expertise in hotels, resorts, and leisure properties. Discover opportunities in the booming Philippine tourism sector."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl font-semibold">
            Hotels, Tourism and Leisure
          </div>
        </div>
      </div>
      <div className="h-12 w-full" />
      <FirstDescription />
      {/* <Accreditation /> */}
      <div className="h-12 w-full" />
      <SolutionsCard />
      <DescriptionBand />
      <ClientTestimonials />
      <ClientsLandlordRep />
    </div>
  );
}

const FirstDescription = () => {
  return (
    <div className=" flex items-center justify-center flex-wrap bg-leechiuBlue">
      <div className="max-w-[800px]">
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721225359/leechiuweb3/Residential/asian-big-family-moving-packing-boxes-and-cloth-ba-2023-11-27-05-34-24-utc_1_m5yrcz.jpg"
          alt=""
          style={{
            maximumWidth: "800px",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="w-[600px] bg-white text-left px-16 py-8">
        <div className="font-semibold">
          <div className="font-serif text-4xl text-leechiuBlue">
            Hotels, Tourism,
          </div>
          <div className="font-serif text-4xl text-leechiuOrange">
            and Leisure
          </div>
        </div>
        <div className="text-leechiuBlue mt-8">
          {`Our Hospitality, Tourism, and Leisure specialists focus on hotel investment sales, research, concept development and planning, operations, and owner advisory for the sector. Our expert advice helps clients maximize value at any stage of the product life cycle.`}
        </div>
      </div>
    </div>
  );
};

const Accreditation = () => {
  const accreditedAgencies = [
    {
      name: "Bangko Sentral ng Pilipinas",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Bangko_Sentral_ng_Pilipinas_2020_logo.png/800px-Bangko_Sentral_ng_Pilipinas_2020_logo.png",
    },
    {
      name: "Securities and Exchange Commission",
      logo: "https://www.sec.gov.ph/wp-content/uploads/2019/12/LogoNew-300x185.png",
    },
    {
      name: "Philippine Stock Exchange",
      logo: "https://cdn-asset.aseanexchanges.org/wp-content/uploads/2023/06/PSE-Logo.png",
    },
  ];

  return (
    <div className="text-leechiuBlue mt-12 bg-white p-8">
      <div className="font-serif text-4xl font-semibold text-center ">
        Accreditations
      </div>
      <div className="flex items-end justify-center gap-8 mt-8">
        {accreditedAgencies.map((agency) => (
          <div className="flex items-center justify-center flex-col">
            <img src={agency.logo} alt={agency.name} className="h-20" />
            <div>{agency.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DescriptionBand = () => {
  return (
    <>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721225802/leechiuweb3/Residential/real-estate-agent-giving-keys-to-happy-arab-buyers-2023-11-27-05-00-08-utc_1_n1eybf.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {` Our Hospitality, Tourism, and Leisure services are designed to empower property owners in the hospitality industry with comprehensive guidance on enhancing their investments, whether in hotels, resorts, or other leisure properties. We advise on strategies to improve the guest experience and boost property performance, and assist property owners in establishing their hospitality businesses from the ground up. Additionally, we offer valuable insights and recommendations for those looking to sell their hospitality investment real estate, guiding them through the sales process with professionalism and market expertise. Trust us to elevate your hospitality investments, realize your entrepreneurial dreams, and navigate the complexities of selling hospitality real estate with confidence and success.`}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                <Link to="/contactUs">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SolutionsCard = () => {
  const cards = [
    {
      title: "Investment Sales",
      description:
        "Leverage our unparalleled reach and expertise to navigate complex hotel, tourism, and leisure asset transactions. We offer comprehensive services for individual and portfolio sales, valuations, and capital market access.",
    },
    {
      title: "Feasibility and Valuations",
      description:
        "Gain unmatched market insights and credible future outlooks through our in-depth data analysis. Our team delivers feasibility studies, market research, and valuations to inform your investment decisions.",
    },
    {
      title: "Hotel and Destination Planning",
      description:
        "We create unique and modern hotel and destination experiences tailored to today's travelers. Our comprehensive services cover planning, design management, and integrated resort development.",
    },
    {
      title: "Branded Residences",
      description:
        "Our dedicated team guides your branded residence project from concept to completion. We offer market analysis, business plan development, and expert sales and marketing strategies for a successful project.",
    },
  ];

  return (
    <div>
      <div className="text-white mt-8">
        <div className="font-serif text-4xl font-semibold">
          {"Our "}
          <span className="text-leechiuOrange">Solutions</span>
        </div>
        <div className="max-w-6xl m-auto mt-4">
          Our Hotels, Tourism, and Leisure services are tailored to empower
          property owners in the hospitality industry with comprehensive
          guidance on improving their investments, whether it be hotels,
          resorts, or other leisure properties. From advising on strategies to
          enhance the guest experience and boost property performance to
          assisting property owners in starting their hospitality businesses
          from the ground up, our expertise covers a wide spectrum of needs in
          the industry. Additionally, we provide valuable insights and
          recommendations to property owners looking to sell their hospitality
          investment real estate, guiding them through the sales process with
          professionalism and market knowledge. Trust us to help you elevate
          your hospitality investments, realize your entrepreneurial dreams, and
          navigate the complexities of selling hospitality real estate with
          confidence and success.
        </div>
      </div>
      <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
        {cards.map((card, index) => {
          return (
            <div
              className="h-32
          max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
              key={index}
            >
              <CheckCircleOutlineIcon />
              <div className="text-leechiuBlue">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Hotels;
