import React from "react";
import { Link } from "react-router-dom";
import PropertiesCardsSection from "./PropertiesCardsSection";

function FeaturedProperties() {
  const backgroundImage =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713799671/leechiu/Research%20and%20Property%20Valuation/u4zifzecyflmvapgiepm.jpg";
  return (
    <div
      className="bg-[url('https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713799671/leechiu/Research%20and%20Property%20Valuation/u4zifzecyflmvapgiepm.jpg')]
     bg-cover bg-center bg-no-repeat  
    
    "
    >
      <div className="w-full bg-leechiuBlue bg-opacity-60 text-white p-8">
        <div className="font-serif text-2xl text-center opacity-1 font-semibold">
          Featured Properties
        </div>

        <div className="text-center mt-8 font-light">
          The properties we handle include houses, lots (all kinds), office
          buildings, malls, resorts, and hotels.{" "}
        </div>

        {/* cards sections */}
        <div
          className="mt-8 flex items-start gap-8
        max-w-6xl m-auto
        "
        >
          {/* <div
            className="h-[400px] w-[300px] bg-white text-neutral-900 flex flex-col items-start justify-center p-8 shrink-0"
            style={{
              borderRadius: "0 10px 10px 0",
            }}
          >
            stationary card
            <div className="text-left">
              <div className="font-serif text-xl font-bold text-leechiuBlue">
                The Upscale Filipino
              </div>
              <div className="font-serif text-leechiuOrange font-bold text-xl">
                Real Estate Market
              </div>
            </div>
            <div className="text-sm font-light mt-8">
              All major properties include offices, industrial lots,
              residential, and mixed-use properties.{" "}
            </div>
            <Link
              to={"/properties"}
              className="mt-8 border border-leechiuBlue border-1 px-2 py-1 text-leechiuBlue"
            >
              Explore All Properties
            </Link>
          </div> */}

          {/* cards components here */}
          <div className=" w-full overflow-hidden">
            <PropertiesCardsSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProperties;
