"use client";
import React from "react";
import { motion } from "framer-motion";
import Partners from "./Partners";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function AboutUs() {
  const videoSource =
    "https://res.cloudinary.com/dtnbpkyvd/video/upload/v1720673254/leechiu/LPC_New_AVP_2023_hjw6l8.mp4";
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Leechiu Property Consultants </title>
        <meta
          name="description"
          content={
            "Leechiu Property Consultants (LPC) is a privately held professional real estate brokerage services company operating in the Philippines."
          }
        />
      </Helmet>
      <div className="text-2xl text-leechiuBlue font-serif font-semibold text-center mt-8">
        About <span className="text-leechiuOrange">Us</span>
      </div>
      <motion.div>
        <video
          className="max-w-[1440px] w-full m-auto"
          src={videoSource}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </motion.div>
      <div className="max-w-5xl m-auto my-8 p-4">
        {` We aim to create superior value for our clients, shareholders, and
        employees. Additionally, we strive to distinguish ourselves as industry
        leaders, recognized for our expertise, advice, and execution, as well as
        our contributions to society, our exceptional work environment, and our
        overall business success.`}
      </div>
      <div className="font-serif text-leechiuBlue text-2xl text-center font-semibold">
        Our Partners <span className="text-leechiuOrange">and Affiliates</span>
      </div>
      <div>
        <Partners />
      </div>
      <div
        className="p-4"
        //  style={{
        //   backgroundImage: "url('https://res.cloudinary.com/dtnbpkyvd/image/upload/v1718206867/leechiucomingsoon/o-buildings_57223990_gbwi5b.jpg')",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
      >
        <div
          className="flex items-center gap-4  max-w-5xl  m-auto  h-48 bg-leechiuBlue text-white mt-8 p-4 bgo-opacity-60"
          style={{
            borderRadius: "0 20px 0 20px",
          }}
        >
          <div className="text-2xl font-bold">
            We are here to help you with your real estate needs.{" "}
          </div>
          <Link
            to="/contactUs"
            className="bg-leechiuOrange px-4 py-2 rounded-xl shrink-0"
          >
            Talk to us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
