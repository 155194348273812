import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import MobileNav from "./MobileNav";
import { AnimatePresence } from "framer-motion";
import ServicesMenu from "./ServicesMenu";
import PropertiesMenu from "./PropertiesMenu";
import InsightsMenu from "./InsightsMenu";
import "./Navbar.css";

function Navbar() {
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [openMenuItem, setOpenMenuItem] = React.useState(null);

  const openMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const handleClick = (index) => {
    if (openMenuItem === index) {
      setOpenMenuItem(null);
    } else {
      setOpenMenuItem(index);
    }
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between bg-leechiuBlue pr-4 ">
        <div className="logo_clip-path min-w-[300px] pl-4 w-[300px] flex items-center justify-center">
          <Link to="/">
            <img
              className=" w-48 my-4"
              src={
                "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1718419894/newlogo_leechiu_sm3mgv.png"
              }
              alt="Leechiu Property Consultants"
            />
          </Link>
        </div>

        <div className="hidden md:flex items-center justify-around text-white flex-wrap w-[1080px] m-auto">
          <button className="navitem-clickable" onClick={() => handleClick(0)}>
            Services
            <KeyboardArrowDownIcon />
          </button>
          <button className="navitem-clickable" onClick={() => handleClick(1)}>
            Properties
            <KeyboardArrowDownIcon />
          </button>
          <Link className="navitem-clickable" to={"/offices"}>
            Offices
            <KeyboardArrowDownIcon />
          </Link>
          <Link className="navitem-clickable" to={"/aboutUs"}>
            About Us
            <KeyboardArrowDownIcon />
          </Link>

          <button className="navitem-clickable" onClick={() => handleClick(4)}>
            Insights
            <KeyboardArrowDownIcon />
          </button>
          <div className="flex items-center gap-8 text-xs">
            <Link
              to="/schedule"
              className="border border-white px-4 py-2 rounded-xl"
            >
              Schedule an Appointment
            </Link>
            <Link
              className="bg-white px-4 py-2 rounded-lg text-gray-800 font-semibold"
              to="/contactUs"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <button className="text-white md:hidden" onClick={openMobileNav}>
          <MenuIcon />
        </button>
      </div>
      <AnimatePresence>
        {mobileNavOpen && <MobileNav setMobileNavOpen={setMobileNavOpen} />}
      </AnimatePresence>

      <AnimatePresence>
        {openMenuItem === 0 && (
          <ServicesMenu setOpenMenuItem={setOpenMenuItem} />
        )}
        {openMenuItem === 1 && (
          <PropertiesMenu setOpenMenuItem={setOpenMenuItem} />
        )}
        {openMenuItem === 4 && (
          <InsightsMenu setOpenMenuItem={setOpenMenuItem} />
        )}
      </AnimatePresence>
    </>
  );
}

export default Navbar;
