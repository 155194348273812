"use client";
import React from "react";
import PropertyCard from "./PropertyCard";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { motion } from "framer-motion";
import { useState } from "react";
import { getAllExlusiveBuildings } from "../Firebase.utils/Firebase.utils";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function PropertiesCardsSection() {
  const [articleIndex, setArticleIndex] = useState(0);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      const properties = await getAllExlusiveBuildings(
        "masterlist",
        "exclusive",
        "Yes"
      );

      //limit to 10 random results only
      // const randomProperties = properties
      //   .sort(() => Math.random() - Math.random())
      //   .slice(0, 10);

      //limit to 10 results
      properties.length = 30;
      const randomProperties = properties;

      //filter properties with primaryPhotoUrl
      // const randomProperties = properties.filter(
      //   (property) => property.primaryPhotoUrl
      // );

      //map properties to image, type, name, location, description1, description2
      const mappedProperties = randomProperties.map((property) => {
        return {
          image:
            property.primaryPhotoUrl ||
            "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721163693/nf75rtkwlbsnckv6eoja.jpg",
          type: "For Lease",
          name: property.buildingName,
          location: property.location,
          description1: `Gross Leasable Area: ${property.grossLeasableArea} `,
          description2: `Typical Floor Plate: ${
            property.floorPlate || "Click to Learn More"
          } `,
          id: property.id,
        };
      });
      // console.log(mappedProperties);
      setProperties(mappedProperties);
    };

    fetchProperties();
  }, []);

  const handleNext = () => {
    if (articleIndex === properties.length) {
      setArticleIndex(-1);
    }

    setArticleIndex((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (articleIndex === 0) {
      return;
    }
    setArticleIndex((prev) => prev - 1);
  };

  return (
    <div>
      <motion.div
        className="flex items-center gap-3"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.1}
        animate={{ translateX: `${articleIndex * -312}px` }}
      >
        <div
          className="h-[420px] w-[300px] bg-white text-neutral-900 flex flex-col items-start justify-center p-8 shrink-0"
          style={{
            borderRadius: "0 10px 10px 0",
          }}
        >
          {/* stationary card */}
          <div className="text-left">
            <div className="font-serif text-xl font-bold text-leechiuBlue">
              The Upscale Filipino
            </div>
            <div className="font-serif text-leechiuOrange font-bold text-xl">
              Real Estate Market
            </div>
          </div>
          <div className="text-sm font-light mt-8">
            All major properties include offices, industrial lots, residential,
            and mixed-use properties.{" "}
          </div>
          <Link
            to={"/offices"}
            className="mt-8 border border-leechiuBlue border-1 px-2 py-1 text-leechiuBlue"
          >
            Explore All Properties
          </Link>
        </div>
        {properties?.map((property, index) => {
          return (
            <PropertyCard
              key={index}
              image={property.image}
              type={property.type}
              name={property.name}
              location={property.location}
              description1={property.description1}
              description2={property.description2}
              id={property.id}
            />
          );
        })}
      </motion.div>
      <div className="flex items-center justify-center font-bold text-xl gap-8 mt-8 text-white ">
        <button
          className="hover:border-2 hover:border-leechiuBlue hover:text-leechiuBlue"
          style={{
            border: "1px solid #E5E5E5",
            borderRadius: "50%",
            padding: "8px",
          }}
          onClick={handlePrev}
        >
          <SlArrowLeft />
        </button>
        <button
          className="hover:border-2 hover:border-leechiuBlue hover:text-leechiuBlue"
          style={{
            border: "1px solid #E5E5E5",
            borderRadius: "50%",
            padding: "8px",
          }}
          onClick={handleNext}
        >
          <SlArrowRight />
        </button>
      </div>
    </div>
  );
}

export default PropertiesCardsSection;
