import React from "react";

import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const MobileNav = ({ setMobileNavOpen }) => {
  const menuItems = [
    {
      name: "Our Services",
      link: "/#services",
    },
    {
      name: "Properties for Sale",
      link: "/for-sale",
    },
    {
      name: "Offices for Lease",
      link: "/offices",
    },
    {
      name: "Landlord Representation",
      link: "/landlord-representation",
    },
    {
      name: "Tenant Representation",
      link: "/tenant-representation",
    },
    {
      name: "About Us",
      link: "/aboutUs",
    },
    {
      name: "Insights",
      link: "/insights",
    },
    {
      name: "Contact Us",
      link: "/contactUs",
    },
  ];

  const handleClick = () => {
    setMobileNavOpen(false);
  };

  return (
    <motion.div
      className="absolute z-10 bg-leechiuBlue text-white w-screen h-screen bg-opacity-80 filter backdrop-blur-sm"
      initial={{ x: "100%", scaleX: 0 }}
      animate={{ x: 0, scaleX: 1 }}
      exit={{ x: "100%", scaleX: 0 }}
      transition={{ duration: 0.3 }}
      onClick={handleClick}
    >
      <div className="text-left w-[350px] m-auto">
        {menuItems.map((item, index) => {
          return (
            <a href={item.link} key={index}>
              <div className="py-4 px-4">{item.name}</div>
            </a>
          );
        })}
      </div>
    </motion.div>
  );
};

export default MobileNav;
