import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getDocData } from "../Firebase.utils/Firebase.utils";
import { useNavigate, useParams } from "react-router-dom";
import { motion, useMotionValue } from "framer-motion";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../Firebase.utils/Firebase.utils";
import { SlArrowLeftCircle } from "react-icons/sl";
import { SlArrowRightCircle } from "react-icons/sl";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

function PropertyDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = React.useState(false);
  const id = searchParams.get("id");
  // const { id } = useParams();
  const { data: property, isLoading } = useQuery(
    "property",
    getDocData.bind(null, "listings", id)
  );
  const [photos, setPhotos] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const dragX = useMotionValue(0);
  const [dragging, setDragging] = React.useState(false);
  const [mobileWidth, setMobileWidth] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      const storageRef = ref(storage, `Photos/Listings/${id}`);
      const photoList = await listAll(storageRef);
      const photoUrls = await Promise.all(
        photoList.items.map(async (item) => {
          return await getDownloadURL(item);
        })
      );
      setPhotos(photoUrls);
    };

    fetchPhotos();
  }, [id]);

  // useEffect(() => {
  //   if (property) {
  //     setSearchParams({
  //       name: property.website_title || property.property_name,
  //       id: id,
  //     });
  //   }
  // }, [property]);

  useEffect(() => {
    // get width of the container
    if (containerRef.current) {
      setMobileWidth(containerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setMobileWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef.current]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen font-serif font-bold text-leechiuBlue  text-4xl ">
        Loading...Please wait.
      </div>
    );
  }

  const tableFields = [
    {
      title: "Property Name",
      value: property.website_title || property.property_name,
    },
    {
      title: "Address",
      value: property.address,
    },

    {
      title: "Asking Price",
      value:
        "Php " +
          parseFloat(property.total_price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || "Contact Agent",
    },
    {
      title: "Price per sqm",
      value:
        "Php " +
          parseFloat(property.selling_price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || "Contact Agent",
    },
    {
      title: "Size",
      value: parseFloat(property.lot_area).toLocaleString() + " sqm",
    },
    {
      title: "Zoning",
      value: property.zoning,
    },

    {
      title: "Description",
      value: property.listing_description,
    },
    // {
    //   title: "Download Flyer",
    //   value: (
    //     <Link className="underline" to={property.files.Flyer} target="_blank">
    //       Download Link
    //     </Link>
    //   ),
    // },
  ];

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);

    if (dragX.get() < -10) {
      if (currentIndex === photos.length - 1) {
        return;
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else if (dragX.get() > 10) {
      if (currentIndex === 0) {
        return;
      } else {
        setCurrentIndex((prev) => prev - 1);
      }
    }
  };

  return (
    <div className="p-4 w-full max-w-[1440px] m-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {property.website_title || property.property_name} | Leechiu Property
          Consultants{" "}
        </title>
        <meta
          name="description"
          content={
            "Looking to buy property in the Philippines? Browse Leechiu's diverse portfolio of properties for sale, including residential, commercial, and industrial listings nationwide."
          }
        />
      </Helmet>
      <div className="font-bold uppercase text-leechiuBlue text-left text-lg">
        {property.website_title || property.property_name}
      </div>
      <div className="h-2 w-96 bg-leechiuOrange mb-8"></div>

      <div className="flex items-start justify-around flex-wrap">
        {/* create a carousel of photos */}
        <motion.div
          className="h-96 aspect-video object-cover rounded-xl flex items-center overflow-hidden relative"
          ref={containerRef}
        >
          <motion.div
            className="flex items-center cursor-grab active:cursor-grabbing"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            style={{
              x: dragX,
            }}
          >
            {photos.map((photo, index) => (
              <div
                key={index}
                className={`h-96 rounded-xl shrink-0 bg-cover bg-center bg-no-repeat `}
                style={{
                  transform: `translateX(-${currentIndex * 100}%)`,
                  transition: "transform 0.5s ease",
                  width: `${mobileWidth}px`,
                }}
              >
                <img
                  src={photo}
                  alt="property"
                  className="h-96 w-full object-cover rounded-xl"
                  // stop the drag event from bubbling up to the parent
                  onDragStart={(e) => e.preventDefault()}
                  onTouchStart={(e) => e.preventDefault()}
                />
              </div>
            ))}
          </motion.div>

          {/* create dot indicators */}
          {photos.length > 1 && (
            <div className="flex items-center justify-center gap-2 absolute bottom-4 p-2 rounded-xl bg-opacity-60 left-1/2 -translate-x-1/2 bg-black w-fit">
              {photos.map((photo, index) => (
                <div
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === currentIndex ? "bg-leechiuOrange" : "bg-white"
                  }`}
                ></div>
              ))}
            </div>
          )}

          {/* create buttons for navigation */}
          {photos.length > 1 && (
            <>
              <div className="absolute top-1/2 left-0 transform -translate-y-1/2 max-sm:hidden ">
                <button
                  className="rounded-full bg-opacity-50"
                  onClick={() => {
                    if (currentIndex === 0) {
                      return;
                    } else {
                      setCurrentIndex((prev) => prev - 1);
                    }
                  }}
                >
                  <SlArrowLeftCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 ml-2" />
                </button>
              </div>
              <div className="absolute top-1/2 right-0 transform -translate-y-1/2 max-sm:hidden">
                <button
                  className="rounded-full bg-opacity-50 "
                  onClick={() => {
                    if (currentIndex === photos.length - 1) {
                      return;
                    } else {
                      setCurrentIndex((prev) => prev + 1);
                    }
                  }}
                >
                  <SlArrowRightCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 mr-2" />
                </button>
              </div>
            </>
          )}
        </motion.div>

        {/* create property details */}
        <div className="w-full sm:w-[600px] border-[1px] border-solid rounded-xl p-2">
          <div className="uppercase font-bold text-leechiuBlue">
            Property Details
          </div>
          <table className="w-full bg-white border border-gray-300  text-neutral-800 mt-4">
            <tbody>
              {tableFields.map((field, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <td className="border border-gray-300 px-2 py-1">
                    {field.title}
                  </td>
                  {field.value === "totalAvailableSpace" ? (
                    <td className="border border-gray-300 px-2 py-1">
                      {parseFloat(property[field.value]).toLocaleString()} sqms
                    </td>
                  ) : (
                    <td className="border border-gray-300 px-2 py-1">
                      {field.value}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="bg-leechiuOrange text-white p-2 rounded-md mt-4"
            onClick={() => setShowModal(true)}
          >
            Inquire About This Property
          </button>
        </div>
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} property={property} id={id} />
      )}
    </div>
  );
}

const InputBox = ({ label, type, name, value, onChange, required }) => {
  return (
    <div className="flex flex-col mb-4">
      <label htmlFor={name} className="text-sm text-white text-left">
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="border border-gray-300 p-2 rounded-md"
        required
      />
    </div>
  );
};

const Modal = ({ property, setShowModal, id }) => {
  const [formData, setFormData] = React.useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataObject = {
      ...formData,
      team_members: property.team_members,
      id: id,
      flyer: property.files.Flyer,
      web_title: property.website_title || property.property_name,
      weblink: `${window.location.href}`,
    };

    // console.log(dataObject);

    const passInfo = async () => {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/forsale",
        {
          method: "POST",
          body: JSON.stringify(dataObject),
        }
      );
    };

    passInfo();

    navigate("/thankyou");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <motion.div
        initial={{
          opacity: 0,
          y: 100,
        }}
        animate={{ opacity: 1, y: 0 }}
      >
        <form
          onSubmit={handleSubmit}
          className="bg-leechiuBlue px-8 py-4 mt-8 rounded-xl w-[350px]"
        >
          <h1 className="text-white text-lg mb-8 font-bold uppercase">
            Inquire About This Property
          </h1>
          <InputBox
            label="Full Name"
            type="text"
            name="name"
            onChange={handleChange}
            required={true}
          />
          <InputBox
            label="Email"
            type="email"
            name="email"
            onChange={handleChange}
            required={true}
          />
          <InputBox
            label="Contact Number"
            type="text"
            name="contact"
            onChange={handleChange}
            required={true}
          />
          <InputBox
            label="Message"
            type="text"
            name="message"
            onChange={handleChange}
          />
          <div className="flex items-center justify-center gap-4">
            <button
              className="bg-white text-nuetral-900 p-2 rounded-md mt-4"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-leechiuOrange text-white p-2 rounded-md mt-4"
              type="submit"
            >
              Send Inquiry
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default PropertyDetails;
