import React from "react";

function ClientsLandlordRep() {
  const clients = [
    {
      name: "Ayala Land",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720943676/leechiuweb3/Landlord%20Representation/logos/Ayala_Land_logo.svg_ceuzvg.png",
    },
    {
      name: "Megaworld",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720943681/leechiuweb3/Landlord%20Representation/logos/megaworld_tjcb2k.png",
    },
    {
      name: "Federal Land",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720943675/leechiuweb3/Landlord%20Representation/logos/federal_nmqqx2.png",
    },
    {
      name: "Rockwell Land",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720943675/leechiuweb3/Landlord%20Representation/logos/rockwell_land_jynyox.png",
    },
    {
      name: "Cathay Land",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720943675/leechiuweb3/Landlord%20Representation/logos/cathayland_vdnwb2.png",
    },
  ];

  return (
    <div className="bg-white mt-8 p-8">
      <div className="text-center font-serif text-3xl font-semibold text-leechiuBlue">
        Clients We've Worked With
      </div>
      <div>
        <div className="flex items-center justify-center flex-wrap gap-4">
          {clients.map((client, index) => {
            return (
              <div className="flex items-center justify-center">
                <img src={client.logo} alt={client.name} className="w-36" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ClientsLandlordRep;
