import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function InsightsPage() {
  const [showModal, setShowModal] = React.useState(false);

  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const fetchReq = async () => {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/insight",
        {
          method: "POST",
          body: JSON.stringify({
            email: formData?.email,
            firstName: formData?.clientName,
          }),
        }
      );
    };
    fetchReq();

    navigate("/thankyou");
  };

  return (
    <div className="bg-leechiuBlue text-white">
      {showModal && (
        <ModalForm
          setShowModal={setShowModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
      <div
        className="h-[500px] flex items-center"
        style={{
          backgroundImage: `url("https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720861950/leechiuweb3/Home/aerial-view-of-makati-2023-11-27-05-24-21-utc_udba4l.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full flex items-center bg-opacity-60">
          <div className="font-serif text-5xl text-white font-semibold text-left ml-10 sm:ml-20">
            <div>Property</div>
            <div>Market Insights</div>
          </div>
        </div>
      </div>
      <div className="p-20 text-left text-2xl">
        Leechiu Property Consultants (LPC) ceaselessly strives for innovation
        and opportunities. We focus on fresh perspectives in our research and
        in-depth, quality data analysis. Our team of experts study the industry
        landscape and uncover market trends and forecasts that make our insights
        your success.
      </div>

      <div className="flex items-center justify-between bg-white px-4 py-2 text-neutral-900 max-w-[1440px] m-auto">
        <div>
          <button
            className="bg-leechiuBlue text-white px-4 py-2 "
            style={{
              borderRadius: "10px 0 0 10px",
            }}
          >
            View All
          </button>
          <button
            className="px-4 py-2 border border-leechiuBlue"
            style={{
              borderRadius: "0 10px 10px 0",
            }}
          >
            Recently
          </button>
        </div>
        <div className="flex items-center gap-4">
          <div className="">
            <input
              className="border-2 border-leechiuBlue rounded-lg px-2 py-1 w-36"
              type="text"
            />
          </div>
          <button>Filter</button>
        </div>
      </div>

      <div className="bg-leechiuBlue my-8 space-y-4">
        <div className="flex items-start justify-center">
          <div className="flex flex-col flex-wrap w-[400px]">
            <div
              className="h-[300px] bg-white"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dtnbpkyvd/image/upload/v1726177015/leechiuweb3/insights/2Q-Market_Insights_LPC_lgrwwv.jpg")`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="text-leechiuBlue bg-white bg-opacity-80 font-serif text-3xl font-semibold">
              <div>q2 PROPERTY</div>
              <div>REPORT 2024:</div>
              <div className="font-sans text-lg text-leechiuOrange">
                CLEARER SKIES AHEAD
              </div>
            </div>
          </div>

          {/* right section */}
          <div className="flex items-start justify-start flex-col w-[400px]">
            <div className="bg-white bg-opacity-70 text-leechiuBlue px-4 py-2">
              2Q Property Report 2024: Clearer Skies Ahead
            </div>
            {/* <div className=" mt-8 px-4 py-2 text-justify">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
              cupiditate nulla ducimus, suscipit veritatis enim accusamus,
              assumenda modi nostrum non fugiat dicta ratione magni excepturi
              provident ea, voluptatibus iste exercitationem.
            </div> */}
            <div className="flex items-center px-4 py-2 gap-2 text-sm">
              <div
                onClick={() => setShowModal(true)}
                className="border border-white bg-leechiuBlue text-white px-4 py-2 cursor-pointer hover:bg-white hover:text-leechiuBlue"
              >
                Download Report
              </div>
              <div className="border border-white bg-leechiuBlue text-white px-4 py-2 cursor-pointer hover:bg-white hover:text-leechiuBlue">
                View Report
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-center">
          <div className="flex flex-col flex-wrap w-[400px]">
            <div
              className="h-[300px] bg-white"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dtnbpkyvd/image/upload/v1726177015/leechiuweb3/insights/1Q_Market_Insights_LPC_g1iabl.jpg")`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="text-leechiuBlue bg-white bg-opacity-80 font-serif text-3xl font-semibold">
              <div>q1 PROPERTY</div>
              <div>REPORT 2024:</div>
              <div className="font-sans text-lg text-leechiuOrange">
                GRACE UNDER PRESSURE
              </div>
            </div>
          </div>

          {/* right section */}
          <div className="flex items-start justify-start flex-col w-[400px]">
            <div className="bg-white bg-opacity-70 text-leechiuBlue px-4 py-2">
              1Q Property Report 2024: Grace Under Pressure
            </div>
            {/* <div className=" mt-8 px-4 py-2 text-justify">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
              cupiditate nulla ducimus, suscipit veritatis enim accusamus,
              assumenda modi nostrum non fugiat dicta ratione magni excepturi
              provident ea, voluptatibus iste exercitationem.
            </div> */}
            <div className="flex items-center px-4 py-2 gap-2 text-sm">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/insights%2FLPC%20-%20March%202024%20Market%20Overview%20-%20Client%20Copy.pdf?alt=media&token=28ce5296-2fa5-4313-b61d-556809f53ef7"
                target="_blank"
                download={true}
                className="border border-white bg-leechiuBlue text-white px-4 py-2 cursor-pointer hover:bg-white hover:text-leechiuBlue"
              >
                Download Report
              </a>
              <div className="border border-white bg-leechiuBlue text-white px-4 py-2 cursor-pointer hover:bg-white hover:text-leechiuBlue">
                View Report
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const InputBox = ({ label, type, placeholder, onChange, ...otherprops }) => {
  return (
    <div className="flex flex-col mb-2">
      <label className="text-xs text-left text-white" htmlFor={label}>
        {label}
      </label>
      <input
        className="border border-leechiuOrange rounded-md p-2 text-neutral-900"
        type={type}
        id={label}
        placeholder={placeholder}
        onChange={onChange}
        {...otherprops}
      />
    </div>
  );
};

const ModalForm = ({ setShowModal, handleChange, handleSubmit }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className=" max-w-[500px] max-h-[500px] flex flex-col items-center justify-center bg-leechiuBlue px-8 py-8">
        <div className="text-sm w-full h-full">
          <div className="mt-8 mb-8 text-sm font-semibold">
            Please fill out the form below to download the report
          </div>
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            <InputBox
              label="Name"
              type="text"
              placeholder="Enter your name"
              name="clientName"
              required="true"
              onChange={handleChange}
            />
            <InputBox
              label="Email"
              type="email"
              placeholder="Enter your email"
              name="email"
              required="true"
              onChange={handleChange}
            />
            <InputBox
              label="Message"
              type="text"
              placeholder="Enter your message"
              name="message"
              required="true"
              onChange={handleChange}
            />
            <div className="flex items-center justify-center gap-4">
              <button
                className="hover:bg-leechiuOrange px-4 py-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="hover:bg-leechiuOrange px-4 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InsightsPage;
