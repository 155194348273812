import React from "react";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";

function HeroInsights() {
  return (
    <div className="text-lg text-neutral-900">
      <div className="bg-white font-bold text-leechiuBlue px-2 py-1">
        Our Q2 Market Insights is{" "}
        <span className="text-leechiuOrange">now available</span>
      </div>
      <div className="bg-leechiuOrange px-2 py-1 text-white font-bold">
        <Link to={"/insights"}>
          <FiDownload className="inline-block mr-2" />
          Download now
        </Link>
      </div>
    </div>
  );
}

export default HeroInsights;
