import React from "react";
import ServicesCards from "./ServicesCards";

function ServicesSection() {
  return (
    <div className="py-8" id="services">
      <div className="flex items-center gap-2 font-serif text-2xl font-semibold justify-center mt-8 mb-8 ">
        <div className="leechiu-blue">Our</div>
        <div className="leechiu-orange">Services</div>
      </div>
      <div className="text-center font-light">
        We help you achieve your business goals with superior value and
        excellence
      </div>
      <div className="cards-container mt-8 max-w-[1400px] m-auto">
        <ServicesCards />
      </div>
    </div>
  );
}

export default ServicesSection;
