import React from "react";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { motion, useMotionValue } from "framer-motion";

function ClientTestimonials() {
  const [imgIndex, setImgIndex] = React.useState(0);
  const [dragging, setDragging] = React.useState(false);

  const testimonials = [];

  const testimonial = [
    {
      clientName: "Active Fun",
      testimonials:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      description:
        "Active Fun is a leading indoor playground and entertainment center in the Philippines. We have been working with Leechiu Property Consultants for the past 5 years and have been extremely satisfied with their services. They have helped us find the perfect location for our business and have negotiated favorable lease terms on our behalf. We highly recommend Leechiu Property Consultants to anyone looking for professional and reliable real estate services.",
      name: "Active Fun",
      title: "CEO",
      logo: "https://contents.smsupermalls.com/data/uploads/2020/07/ACTIVE_FUN.png",
    },
    {
      clientName: "Active Fun",
      testimonials:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      description:
        "Active Fun is a leading indoor playground and entertainment center in the Philippines. We have been working with Leechiu Property Consultants for the past 5 years and have been extremely satisfied with their services. They have helped us find the perfect location for our business and have negotiated favorable lease terms on our behalf. We highly recommend Leechiu Property Consultants to anyone looking for professional and reliable real estate services.",
      name: "Active Fun",
      title: "CEO",
      logo: "https://contents.smsupermalls.com/data/uploads/2020/07/ACTIVE_FUN.png",
    },
    {
      clientName: "Citibank",
      testimonials:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      description:
        "Active Fun is a leading indoor playground and entertainment center in the Philippines. We have been working with Leechiu Property Consultants for the past 5 years and have been extremely satisfied with their services. They have helped us find the perfect location for our business and have negotiated favorable lease terms on our behalf. We highly recommend Leechiu Property Consultants to anyone looking for professional and reliable real estate services.",
      name: "Citibank",
      title: "CEO",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Citi.svg/2560px-Citi.svg.png",
    },
    {
      clientName: "Active Fun",
      testimonials:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      description:
        "Active Fun is a leading indoor playground and entertainment center in the Philippines. We have been working with Leechiu Property Consultants for the past 5 years and have been extremely satisfied with their services. They have helped us find the perfect location for our business and have negotiated favorable lease terms on our behalf. We highly recommend Leechiu Property Consultants to anyone looking for professional and reliable real estate services.",
      name: "Active Fun",
      title: "CEO",
      logo: "https://contents.smsupermalls.com/data/uploads/2020/07/ACTIVE_FUN.png",
    },
    {
      clientName: "Active Fun",
      testimonials:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      description:
        "Active Fun is a leading indoor playground and entertainment center in the Philippines. We have been working with Leechiu Property Consultants for the past 5 years and have been extremely satisfied with their services. They have helped us find the perfect location for our business and have negotiated favorable lease terms on our behalf. We highly recommend Leechiu Property Consultants to anyone looking for professional and reliable real estate services.",
      name: "Active Fun",
      title: "CEO",
      logo: "https://contents.smsupermalls.com/data/uploads/2020/07/ACTIVE_FUN.png",
    },
  ];

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);

    if (dragX.get() < -50) {
      if (imgIndex === testimonials.length - 1) {
        return;
      } else {
        setImgIndex((prev) => prev + 1);
      }
    } else if (dragX.get() > 50) {
      if (imgIndex === 0) {
        return;
      } else {
        setImgIndex((prev) => prev - 1);
      }
    }
  };

  const dragX = useMotionValue(0);

  return (
    <div className="p-8 overflow-hidden">
      {/* <div className="font-serif text-2xl txt-white text-left text-white font-semibold">
        {"Client "}
        <span className="text-leechiuOrange">Testimonials</span>
      </div> */}
      {/* Cards for testimonials */}
      <motion.div
        className="flex items-center justify-start mt-8"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        animate={{
          translateX: `-${imgIndex * 360}px`,
        }}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        style={{
          x: dragX,
        }}
      >
        {testimonials.map((testimonial, index) => {
          return (
            <div className="flex items-start justify-center gap-2 w-[350px]">
              <div
                className="h-28 w-28 bg-white p-2"
                style={{
                  backgroundImage: `url(${testimonial.logo})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                }}
              ></div>
              <div className="flex flex-col items-start justify-start w-60 text-white text-xs space-y-4">
                <FormatQuoteIcon className="text-leechiuOrange h-12" />
                <div className="text-left">{testimonial.testimonials}</div>
                <div className="text-left text-[.5rem]">
                  {testimonial.description}
                </div>
                <div className="text-left">
                  <div className="text-leechiuOrange font-semibold">
                    {testimonial.name}
                  </div>
                  <div className="text-gray-500">{testimonial.title}</div>
                </div>
              </div>
            </div>
          );
        })}
      </motion.div>
    </div>
  );
}

export default ClientTestimonials;
