import React from "react";
import ClientsLandlordRep from "./ClientsLandlordRep";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import { useEffect } from "react";

function Valuation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backImg =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721204400/leechiuweb3/PROJECT%20EVAL/new-strategies-2023-11-27-05-36-27-utc_1_ebx2sn.jpg";

  return (
    <div className="bg-leechiuBlue">
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl font-semibold">
            Valuation and Appraisal
          </div>
        </div>
      </div>
      <div className="h-20 w-full" />
      <FirstDescription />
      <Accreditation />
      <div className="h-12 w-full" />
      <DescriptionBand />
      <SolutionsCard />
      <ClientTestimonials />
      <ClientsLandlordRep />
    </div>
  );
}

const FirstDescription = () => {
  return (
    <div className=" flex items-center justify-center flex-wrap">
      <div className="w-[600px] bg-white text-left px-16 py-8">
        <div className="font-semibold">
          <div className="font-serif text-4xl text-leechiuBlue">
            Seamless Project
          </div>
          <div className="font-serif text-4xl text-leechiuOrange">Success</div>
        </div>
        <div className="text-leechiuBlue mt-8">
          Our valuation team undertakes fixed assets appraisal and the valuation
          of ongoing business concerns.
        </div>
      </div>
      <div className="max-w-[800px]">
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721205129/leechiuweb3/PROJECT%20EVAL/shot-of-a-group-of-businesspeople-listening-to-a-p-2023-11-27-05-15-43-utc_1_pxq6nb.jpg"
          alt=""
          style={{
            maximumWidth: "800px",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

const Accreditation = () => {
  const accreditedAgencies = [
    {
      name: "Bangko Sentral ng Pilipinas",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Bangko_Sentral_ng_Pilipinas_2020_logo.png/800px-Bangko_Sentral_ng_Pilipinas_2020_logo.png",
    },
    {
      name: "Securities and Exchange Commission",
      logo: "https://www.sec.gov.ph/wp-content/uploads/2019/12/LogoNew-300x185.png",
    },
    // {
    //   name: "Philippine Stock Exchange",
    //   logo: "https://cdn-asset.aseanexchanges.org/wp-content/uploads/2023/06/PSE-Logo.png",
    // },
  ];

  return (
    <div className="text-leechiuBlue mt-12 bg-white p-8">
      <div className="font-serif text-4xl font-semibold text-center ">
        Accreditations
      </div>
      <div className="flex items-end justify-center gap-8 mt-8">
        {accreditedAgencies.map((agency) => (
          <div className="flex items-center justify-center flex-col">
            <img src={agency.logo} alt={agency.name} className="h-20" />
            <div>{agency.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DescriptionBand = () => {
  return (
    <>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721222128/leechiuweb3/PROJECT%20EVAL/people-meeting-brainstorming-blueprint-design-conc-2023-11-27-05-26-14-utc_1_u8foxw.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {` Our valuation team undertakes fixed assets appraisal and the valuation of ongoing business concerns. The properties that we appraise include houses, lots (all kinds), office buildings, malls, resorts, and hotels. Our reports follow international standards of valuation.`}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                <Link to="/contactUs">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SolutionsCard = () => {
  const cards = [
    {
      title: "Comprehensive Property Reports",
      description:
        "Access detailed reports that go beyond surface-level information, offering a deep understanding of each asset.",
    },
    {
      title: "Tailored to Your Business",
      description:
        "Our services are customized to meet your unique business requirements, ensuring that the information you receive is both relevant and impactful.",
    },
    {
      title: "Strategic Decision-Making",
      description:
        "Make informed decisions backed by data-driven insights, giving you a competitive advantage in the real estate market.",
    },
    {
      title: "Market Trends Analysis",
      description:
        "Stay ahead of the curve with our in-depth analysis of market trends, allowing you to anticipate shifts and capitalize on emerging opportunities.",
    },
    {
      title: "Expert Consultancy",
      description:
        "Benefit from the expertise of our seasoned consultants, who are dedicated to offering valuable insights and guidance.",
    },
  ];

  return (
    <div>
      <div className="text-white mt-8">
        <div className="font-serif text-4xl font-semibold">
          {"Our "}
          <span className="text-leechiuOrange">Solutions</span>
        </div>
        <div className="max-w-6xl m-auto mt-4">
          {`Our Valuation Services are dedicated to enhancing your real estate investments through precise and meticulous property appraisal and valuation. Supported by a team of experienced professionals, we specialize in providing accurate and reliable valuations that empower you to make informed decisions with confidence. Whether you need to assess the value of residential, commercial, or industrial properties, our expertise ensures that you gain a comprehensive understanding of the market value of your assets. Trust us to deliver detailed and insightful valuation reports tailored to your specific needs, helping you optimize your real estate portfolio with clarity and precision.`}
        </div>
      </div>
      <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
        {cards.map((card, index) => {
          return (
            <div
              className="h-32
          max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
              key={index}
            >
              <CheckCircleOutlineIcon />
              <div className="text-leechiuBlue">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Valuation;
