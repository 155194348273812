import React from "react";

function ThankYou() {
  return (
    <div className="text-4xl py-8 h-[350px] flex flex-col items-center justify-center bg-leechiuBlue text-white">
      <div>Thank you for your inquiry</div>
      <div className="text-lg font-serif text-leechiuOrange">
        Please check your email for our response
      </div>
    </div>
  );
}

export default ThankYou;
